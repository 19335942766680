import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

const ElevationSelect = ({ value, onChange }) => {
  const formatElevation = (value) => {
    const num = parseInt(value);
    if (num >= 1000) {
      return `${num/1000}k ft`;
    }
    return `${num} ft`;
  };

  const elevations = [
    { value: '100000', label: '100,000' },
    { value: '50000', label: '50,000' },
    { value: '25000', label: '25,000' },
    { value: '10000', label: '10,000' },
    { value: '5000', label: '5,000' },
    { value: '1000', label: '1,000' }
  ];

  return (
    <Select 
      value={value} 
      onValueChange={onChange}
    >
      <SelectTrigger className="w-32">
        <SelectValue placeholder="Select elevation">
          {formatElevation(value)}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {elevations.map(elevation => (
          <SelectItem 
            key={elevation.value} 
            value={elevation.value}
          >
            {elevation.label} ft
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default ElevationSelect;
import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, Check } from 'lucide-react';

const MapDropdown = ({ 
  maps, 
  currentMapId, 
  onSelect, 
  className = "" 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getCurrentMapName = () => {
    if (!currentMapId) return "Load saved map...";
    const currentMap = maps.find(map => map.id === currentMapId);
    return currentMap ? currentMap.name : "Load saved map...";
  };

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-64 px-3 py-2 text-left bg-white border rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between"
      >
        <span className="truncate">{getCurrentMapName()}</span>
        <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute z-50 w-96 mt-1 bg-white border rounded-md shadow-lg max-h-96 overflow-y-auto">
          <div className="py-1">
            {maps.length === 0 ? (
              <div className="px-4 py-2 text-gray-500 italic">No saved maps</div>
            ) : (
              maps.map(map => (
                <button
                  key={map.id}
                  onClick={() => {
                    onSelect(map.id);
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center justify-between group"
                >
                  <div className="flex-1 min-w-0">
                    <div className="font-medium truncate">{map.name}</div>
                    <div className="text-sm text-gray-500 truncate">
                      Last edited: {new Date(map.updatedAt).toLocaleDateString()}
                    </div>
                  </div>
                  {currentMapId === map.id && (
                    <Check className="w-4 h-4 text-blue-500" />
                  )}
                </button>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MapDropdown;
// src/App.jsx
import React from 'react';
import ProblemMap from './components/ProblemMap';
import { FirebaseProvider } from './lib/FirebaseContext';
import AuthHeader from './components/AuthHeader';

const App = () => {
  return (
    <FirebaseProvider>
      <div className="h-screen flex flex-col">
        <header className="bg-white shadow-sm px-4 py-3 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">arbr</h1>
          <AuthHeader />
        </header>
        <main className="flex-1">
          <ProblemMap />
        </main>
      </div>
    </FirebaseProvider>
  );
};

export default App;
// src/components/ProblemNode.jsx
import React, { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { PlusCircle, Trash2 } from 'lucide-react';

const ProblemNode = ({ 
  elevation = '100k', 
  initialText = 'New Problem',
  onDelete = null,
  id,
  onChange,
  savedData = null,
  selectedId = null,      // Add this prop
  setSelectedId = null,   // Add this prop
  parentPath = []         // Add this prop for tracking hierarchy
}) => {
  console.log('ProblemNode rendering with savedData:', savedData);

  const [text, setText] = useState(() => {
    if (savedData && typeof savedData.text === 'string') {
      return savedData.text;
    }
    return initialText;
  });

  const [children, setChildren] = useState(() => {
    if (savedData && Array.isArray(savedData.children)) {
      return savedData.children.map(child => ({
        id: child.id || `${id}-${Math.random()}`,
        text: child.text || 'Child Problem',
        children: Array.isArray(child.children) ? child.children : []
      }));
    }
    return [];
  });

  // Calculate if this node is in the selected path
  const currentPath = [...parentPath, id];
  const isInSelectedPath = selectedId === id || 
    currentPath.includes(selectedId) || 
    selectedId?.startsWith(id);

  const elevationLevels = ['100k', '50k', '25k', '10k', '5k', '1k'];
  
  const getNextElevation = (currentElevation) => {
    const currentIndex = elevationLevels.indexOf(currentElevation);
    return currentIndex < elevationLevels.length - 1 
      ? elevationLevels[currentIndex + 1] 
      : elevationLevels[elevationLevels.length - 1];
  };

  const elevationColors = {
    '100k': 'bg-blue-50',
    '50k': 'bg-blue-100',
    '25k': 'bg-blue-200',
    '10k': 'bg-blue-300',
    '5k': 'bg-blue-400',
    '1k': 'bg-blue-500'
  };

  const notifyParent = (updatedChildren = children, updatedText = text) => {
    onChange?.({
      id,
      text: updatedText,
      children: updatedChildren
    });
  };

  const handleTextChange = (newText) => {
    setText(newText);
    notifyParent(children, newText);
  };

  const addChild = () => {
    const newChild = {
      id: `${id}-${children.length}`,
      text: 'Child Problem',
      children: []
    };
    const newChildren = [...children, newChild];
    setChildren(newChildren);
    notifyParent(newChildren);
  };

  const removeChild = (childId) => {
    const newChildren = children.filter(child => child.id !== childId);
    setChildren(newChildren);
    notifyParent(newChildren);
  };

  const handleChildChange = (childData) => {
    console.log('Child changed:', childData);
    const newChildren = children.map(child => 
      child.id === childData.id ? {
        ...childData,
        children: Array.isArray(childData.children) ? childData.children : []
      } : child
    );
    setChildren(newChildren);
    notifyParent(newChildren);
  };

  return (
    <div className="flex flex-col items-center">
      <div className={`flex flex-col items-center ${children.length === 0 ? 'w-64' : ''}`}>
        <div className={`w-full ${children.length > 0 ? 'min-w-full' : ''}`}>
          <Card 
            className={`${elevationColors[elevation]} relative group transition-opacity duration-200
              cursor-pointer hover:ring-2 hover:ring-blue-200
              ${selectedId && !isInSelectedPath ? 'opacity-20' : 'opacity-100'}`}
            onClick={() => setSelectedId?.(selectedId === id ? null : id)}
          >
            <CardContent className="p-4 relative">
              <div className="flex items-center gap-2">
                <span className="text-xs font-medium bg-white/50 px-2 py-1 rounded">
                  {elevation}
                </span>
                <textarea
                  className="flex-1 bg-transparent border-none resize-none focus:outline-none"
                  value={text}
                  onChange={(e) => handleTextChange(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedId?.(id);
                  }}
                  rows={1}
                />
                {onDelete && (
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                    className="absolute -right-2 -top-2 p-1 rounded-full bg-white shadow opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-50"
                  >
                    <Trash2 className="w-4 h-4 text-red-500" />
                  </button>
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="mt-2 mb-8">
          <button 
            onClick={(e) => {
              e.stopPropagation();
              addChild();
            }}
            className="hover:scale-110 transition-transform"
          >
            <PlusCircle className="w-6 h-6 text-gray-500" />
          </button>
        </div>

        {children && children.length > 0 && (
          <div className="flex flex-row gap-8 items-start w-full justify-between">
            {children.map(child => (
              <div key={child.id} className="flex-grow flex justify-center min-w-64">
                <ProblemNode
                  id={child.id}
                  elevation={getNextElevation(elevation)}
                  onDelete={() => removeChild(child.id)}
                  onChange={handleChildChange}
                  savedData={child}
                  initialText={child.text}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  parentPath={currentPath}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProblemNode;
// src/lib/FirebaseContext.jsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

const FirebaseContext = createContext({
  user: null,
  loading: true
});

export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('FirebaseProvider mounting'); // Add this to debug
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user); // Add this to debug
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  console.log('FirebaseProvider rendering:', { user, loading }); // Add this to debug

  return (
    <FirebaseContext.Provider value={{ user, loading }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};
import React, { useState, useEffect } from 'react';
import ProblemNode from './ProblemNode';
import { useFirebase } from '../lib/FirebaseContext';
import { saveProblemMap, getUserProblemMaps, updateProblemMap } from '../lib/problemMapStore';
import { ZoomIn, ZoomOut, Save } from 'lucide-react';
import MapDropdown from './MapDropdown';
import ElevationSelect from './ElevationSelect';


const ProblemMap = () => {
    const { user } = useFirebase();
    const [zoom, setZoom] = useState(1);
    const [defaultElevation, setDefaultElevation] = useState('100k');
    const [currentMapId, setCurrentMapId] = useState(null);
    const [savedMaps, setSavedMaps] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [rootNode, setRootNode] = useState({
        id: 'root',
        text: 'Root Problem',
        children: []
    });

    useEffect(() => {
        if (user) {
            loadUserMaps();
        } else {
            setSavedMaps([]);
            setCurrentMapId(null);
        }
    }, [user]);

    const loadUserMaps = async () => {
        try {
            const maps = await getUserProblemMaps(user.uid);
            // Sort maps by last modified date, but maintain the map name
            const sortedMaps = maps.sort((a, b) =>
                new Date(b.updatedAt) - new Date(a.updatedAt)
            ).map(map => ({
                ...map,
                name: map.mapData.trees[0]?.text || 'Untitled Map'
            }));
            setSavedMaps(sortedMaps);
        } catch (error) {
            console.error('Error loading maps:', error);
        }
    };

    const handleNodeChange = (nodeData) => {
        setRootNode(nodeData);
        // If this is the root node, update the map name in the saved maps list
        if (nodeData.id === 'root' && currentMapId) {
            setSavedMaps(prev => prev.map(map =>
                map.id === currentMapId
                    ? { ...map, name: nodeData.text }
                    : map
            ));
        }
    };

    const handleSave = async () => {
        if (!user) return;

        setSaving(true);
        try {
            const cleanNode = (node) => ({
                id: node.id,
                text: node.text,
                children: (node.children || []).map(cleanNode)
            });

            const mapData = {
                trees: [cleanNode(rootNode)],
                defaultElevation,
                name: rootNode.text // Store the name explicitly in the map data
            };

            if (currentMapId) {
                await updateProblemMap(currentMapId, mapData);
            } else {
                const newMapId = await saveProblemMap(user.uid, mapData);
                setCurrentMapId(newMapId);
            }

            await loadUserMaps();
        } catch (error) {
            console.error('Error saving map:', error);
        } finally {
            setSaving(false);
        }
    };

    const loadMap = async (mapId) => {
        setLoading(true);
        try {
            const selectedMap = savedMaps.find(map => map.id === mapId);
            if (selectedMap?.mapData?.trees[0]) {
                setRootNode(null);

                setTimeout(() => {
                    const mapTree = selectedMap.mapData.trees[0];
                    const newRootNode = {
                        id: mapTree.id || 'root',
                        text: mapTree.text || 'Untitled Map',
                        children: mapTree.children || []
                    };

                    setRootNode(newRootNode);
                    setDefaultElevation(selectedMap.mapData.defaultElevation || '100k');
                    setCurrentMapId(mapId);
                }, 0);
            }
        } catch (error) {
            console.error('Error loading map:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleNewMap = () => {
        setCurrentMapId(null);
        setRootNode({
            id: 'root',
            text: 'New Problem Map',
            children: []
        });
    };

    const handleZoom = (direction) => {
        setZoom(prev => {
            const newZoom = direction === 'in' ? prev * 1.2 : prev / 1.2;
            return Math.min(Math.max(0.5, newZoom), 2);
        });
    };

    if (!user) {
        return null;
    }

    return (
        <div className="h-screen flex flex-col">
            <div className="border-b bg-white p-4 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <ElevationSelect
                        value={defaultElevation}
                        onChange={(newValue) => setDefaultElevation(newValue)}
                    />

                    {user && (
                        <>
                            <MapDropdown
                                maps={savedMaps}
                                currentMapId={currentMapId}
                                onSelect={loadMap}
                                className="flex-shrink-0"
                            />

                            <div className="flex gap-2">
                                <button
                                    onClick={handleNewMap}
                                    className="px-4 py-1 border rounded hover:bg-gray-50"
                                >
                                    New Map
                                </button>

                                <button
                                    onClick={handleSave}
                                    disabled={saving}
                                    className="px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 flex items-center gap-2"
                                >
                                    <Save className="w-4 h-4" />
                                    {saving ? 'Saving...' : 'Save Map'}
                                </button>
                            </div>
                        </>
                    )}
                </div>

                <div className="flex items-center gap-2">
                    <button
                        onClick={() => handleZoom('out')}
                        disabled={zoom <= 0.5}
                        className="p-1 border rounded hover:bg-gray-50 disabled:opacity-50"
                    >
                        <ZoomOut className="h-4 w-4" />
                    </button>
                    <span className="text-sm text-gray-500 w-16 text-center">
                        {Math.round(zoom * 100)}%
                    </span>
                    <button
                        onClick={() => handleZoom('in')}
                        disabled={zoom >= 2}
                        className="p-1 border rounded hover:bg-gray-50 disabled:opacity-50"
                    >
                        <ZoomIn className="h-4 h-4" />
                    </button>
                </div>
            </div>

            <div className="flex-1 overflow-auto bg-gray-50 p-8 relative">
                {loading && (
                    <div className="absolute inset-0 bg-white/50 flex items-center justify-center">
                        <div className="text-lg">Loading map...</div>
                    </div>
                )}
                <div className="flex justify-center">
                    <div
                        className="inline-block"
                        style={{
                            transform: `scale(${zoom})`,
                            transformOrigin: 'top center',
                            transition: 'transform 0.2s ease-in-out'
                        }}
                    >
                        {rootNode && (
                            <ProblemNode
                                key={`${currentMapId}-${rootNode.id}`}
                                id={rootNode.id}
                                elevation={`${parseInt(defaultElevation) / 1000}k`}
                                onChange={handleNodeChange}
                                savedData={rootNode}
                                initialText={rootNode.text}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProblemMap;
// src/lib/problemMapStore.js
import { db } from './firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  where, 
  getDocs 
} from 'firebase/firestore';

// Helper to clean undefined values from an object
const cleanObject = (obj) => {
  const cleaned = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        cleaned[key] = value.map(item => 
          typeof item === 'object' ? cleanObject(item) : item
        );
      } else if (value && typeof value === 'object') {
        cleaned[key] = cleanObject(value);
      } else {
        cleaned[key] = value;
      }
    }
  });
  return cleaned;
};

// Helper to serialize the tree structure
const serializeNode = (node) => {
  if (!node) return null;
  return cleanObject({
    id: node.id || `node-${Date.now()}`,
    text: node.text || '',
    children: node.children || []
  });
};

export const saveProblemMap = async (userId, mapData) => {
  try {
    // Clean and serialize the tree structure
    const cleanData = cleanObject({
      trees: mapData.trees.map(tree => ({
        id: tree.id,
        text: tree.text || '',
        children: tree.children || []
      })),
      defaultElevation: mapData.defaultElevation || '100k'
    });

    const docData = {
      userId,
      mapData: cleanData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    const docRef = await addDoc(collection(db, 'problemMaps'), docData);
    return docRef.id;
  } catch (error) {
    console.error('Error saving problem map:', error);
    throw error;
  }
};

export const updateProblemMap = async (mapId, mapData) => {
  try {
    // Clean and serialize the tree structure
    const cleanData = cleanObject({
      trees: mapData.trees.map(tree => ({
        id: tree.id,
        text: tree.text || '',
        children: tree.children || []
      })),
      defaultElevation: mapData.defaultElevation || '100k'
    });

    const docRef = doc(db, 'problemMaps', mapId);
    await updateDoc(docRef, {
      mapData: cleanData,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating problem map:', error);
    throw error;
  }
};

export const getUserProblemMaps = async (userId) => {
  try {
    const q = query(
      collection(db, 'problemMaps'), 
      where('userId', '==', userId)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting user problem maps:', error);
    throw error;
  }
};